<template>
  <div class="home">

    <Header/>

    <section id="home-banner">
      <div class="container">
        <div class="row">
          <div class="col-md-6 align-self-center">
              <h1 data-aos="fade-right">Dream. <br> Design. <br> Develop.</h1>
          </div>
          <div class="col-md-6 align-self-center">
            <div class="liquid-wrapper">
              <div class="liquid"></div>
            </div>
          </div>
        </div>
        <a href="#vexvo-video" class="scroll-down">SCROLL DOWN
          <div class="scroll-down-bar"></div>
        </a>
        
      </div>
    </section>

    <section id="vexvo-video">
      <div class="container">
        <div class="video-wrapper" :class="{active : playActive}">
          <vue-plyr ref="plyr" :options="videooptions">
            <div class="plyr__video-embed" style="border-radius: 32px;">
              <iframe
               title="vimeo-player" id="vexvoIframe"
              src="https://www.youtube.com/embed/juGxhi_psrk"
              width="1000" height="360" frameborder="0"
              allowfullscreen></iframe>
            </div>
          </vue-plyr>
          <p class="play" v-on:click="playVideo()">Play</p>
        </div>
      </div>
    </section>

    <section id="about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-6" >
            <div class="image-wrapper" data-aos="fade-right">
              <img src="../assets/image/about-us-image.jpg" alt="">
            </div>
          </div>
          <div class="col-md-6 align-self-center" data-aos="fade-left">
            <div class="content-wrapper">
              <h2 class="h2">About us.</h2>
              <div class="row">
                <div class="col-md-12 col-xl-6">
                  <strong>
                    Vexvo strives to <br>
                    provide digital <br>
                    solutions giving UI and <br>
                    UX the highest priority.
                  </strong>
                </div>
                <div class="col-md-12 col-xl-6 align-self-center">
                  <p>We rethink the way problems can be tackled problems in simple, efficient, innovative and scalable ways. We provide the strategy, experience, and technical expertise to help you thrive in the new dynamic world. 
                    <br> <br>
                     We know how automation, sensors, data analytics, and cognitive computing can help platforms advance and grow, as well as how these same tools will allow our products to keep up with the demands of customers.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="our-services">
      <div class="container">
        <h2 class="h2">Our services</h2>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-4 col-md-6" data-aos="flip-left">
            <div class="card-wrapper">
              <h3>Web Development</h3>
              <p>Web development services assist in creating a wide range of web-based software and provide a positive user experience. Vexvo creates high-converting and high-adoption online apps by professionally designing, redesigning, and continuously supporting them.</p>
            </div>
          </div>
          <div  class="col-xl-4 col-md-6" data-aos="flip-right">
            <div class="card-wrapper">
              <h3>UI Design</h3>
              <p>The goal of UI design services is to create visually appealing, brand-building digital interfaces that attract and retain customers. Vexvo creates user interfaces targeted to your audience, represents customer values, and effectively supports your company goals.</p>
            </div>
          </div>
          <div class="col-xl-4 col-md-6" data-aos="flip-right">
            <div class="card-wrapper">
              <h3>Social Automation</h3>
              <p>Social automation solutions provided by Vexvo can help you get your page/community to grow faster. Businesses confront challenges due to dynamically changing apps. Web test automation service providers can help you address all of your problems.</p>
            </div>
          </div>

          <div class="col-xl-4 col-md-6 " data-aos="flip-left">
            <div class="card-wrapper">
              <h3>Machine Learning</h3>
              <p>We provide a wide variety of machine learning services to assist enterprises in addressing business challenges with accurate forecasts and predictions, root-cause analysis, (extensive) data mining, and more, thanks to its experience in data science and A.I.</p>
            </div>
          </div>
          <div class="col-xl-4 col-md-6" data-aos="flip-right">
            <div class="card-wrapper">
              <h3>Cyber Security</h3>
              <p>We aim for a better & safer world. Our solutions on this field fights against fraud & malicious users. We reduce the number of security flaws in web, mobile, and desktop apps and our clients' networks by a significant amount. </p>
            </div>
          </div>
          <div class="col-xl-4 col-md-6" data-aos="flip-right">
            <div class="card-wrapper">
              <h3>Digital fingerprinting</h3>
              <p>As our collective internet privacy begins to slide through the cracks of an increasingly complicated online world, digital fingerprinting has become a hot topic in recent years. We expertise in helping you recognize your users and the bad actors.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="our-works">
      <div class="container">
        <h2 class="h2">Our Works</h2>
        <div class="partnerships-wrapper" data-aos="fade-left">
          <ul>
            <li>
              <img src="../assets/image/logo2.png" height="85px" alt="partnerships Image">
            </li>
            <li>
              <img src="../assets/image/partnerships-cross.png" alt="partnerships Image">
            </li>
            <li>
              <img src="../assets/image/discortics.png" height="112px" alt="partnerships Image">
            </li>
          </ul>
          <div class="main-card">
            
              <h3><a href="https://discortics.com">Discortics</a></h3>
            
              <p>Discortics is your Prime alternatives to bots for a better Discord. You get to implement with the easy to setup, commands user friendly, and adorable Discortics. Enhance your server theme as per your needs. Aesthetic? Gaming? Futuristic? You name it!  </p>
            
          </div>
        </div>

<!--        <a href="#" class="view-more">VIEW MORE</a>-->
      </div>
    </section>

    <section id="our-team">
      <div class="container">
        <h2 class="h2">Team</h2>
        <div class="team-wrapper">
          <div class="team-card" data-aos="zoom-in">
            <div class="imgDiv">
              <img src="../assets/image/team-member-1.png" alt="our team">
            </div>
            <div class="contentDiv">
              <h3>Joshua Varghese</h3>
              <strong>Managing Director</strong>
              <p>Joshua is our managing director, and he's the reason you're here right now reading our website. He ensures that our projects are up and working smoothly & effectively regularly. </p>
            </div>
          </div>
          <div class="team-card" data-aos="zoom-in">
            <div class="imgDiv">
              <img src="../assets/image/team-member-2.png" alt="our team">
            </div>
            <div class="contentDiv">
              <h3>Varghese Thomas</h3>
              <strong>Director</strong>
              <p>Our Thomas is a curious soul who enjoys work that pushes him to learn new things and stretch in new directions. He ensures the flow of established standards & procedure in each progress we make. </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="faq">
      <div class="container" data-aos="fade-up">
        <h2 class="small-h2">FAQs</h2>

        <VueFaqAccordion
            :items="faqItems"
        />
      </div>
    </section>

    <footer id="footer">
      <div class="container">
        <h2>Contact Us</h2>
        <form class="form" data-aos="fade-up" v-if="formSubmit">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="name">your name*</label>
                <input type="text" v-model="name" id="name" class="form-control">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="email">Email*</label>
                <input type="email" v-model="email" id="email" class="form-control">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="location">Location*</label>
                <input type="text" v-model="location" id="location" class="form-control">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="company">Company Name*</label>
                <input type="text" v-model="company" id="company" class="form-control">
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <button class="form-btn" v-on:click="postNow" >Submit</button>
              </div>
            </div>
          </div>
          <span v-if="errorMsg !== ''" v-html="errorMsg" class="error-message"></span>
        </form>
        <div class="form-success" v-if="formSuccess">
          <img src="../assets/image/check.svg" alt="Success">
          <h3>Thank You!</h3>
          <p>Your submission has been sent.</p>
        </div>
        <div class="bottom-footer">
          <div class="row">
            <div class="col-md-6">
              <img src="../assets/image/footer-logo.png" class="footer-logo" alt="Footer logo">
              <p>© {{ year }}, Vexvo Pvt. Ltd.  All Rights Reserved.</p>
            </div>
            <div class="col-md-6">
              <a href="https://discortics.com/privacy">Privacy Policy</a>
            </div>
          </div>
        </div>
      </div>
    </footer>

  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header';
import VueFaqAccordion from 'vue-faq-accordion';
import axios from "axios";

export default {
  name: 'Home',
  components: {
    Header,
    VueFaqAccordion,
  },
  mounted() {
    this.player = this.$refs.plyr.player;
  },
  data(){
    return{
      year: new Date().getFullYear(),
      playActive: false,
      player: null,
      videooptions: {
        controls: [ 'restart', 'rewind', 'play', 'fast-forward', 'progress', 'current-time', 'duration', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen'
]
      },
      faqItems: [
        {
          title: 'Do you accept gigs/offers',
          value: 'Yes, we do accept challenging gigs/offers.',
          category: 'Tab-1'
        },
        {
          title: 'What makes you stand out from your competitors?',
          value: 'Our projects make us stand out from our competitors in design, user experience, etc. We make sure to give 100% customer satisfaction, and we are very strict when it comes to our project delivery time. ',
          category: 'Tab-1'
        },
        {
          title: 'How can I contact you?',
          value: 'You can contact us at info@vexvo.io or in the contact section. ',
          category: 'Tab-1'
        },
        {
          title: 'What kind of services are you providing?',
          value: 'At this moment, we are providing web development services, UI Designing, Social Automation, ML services, CyberSecurity services, and many more. You can email us or contact us for other pieces of information. ',
          category: 'Tab-1'
        },
        {
          title: 'Are you guys expensive?',
          value: "Your payment differs from service to service. It depends on what kind of services you are looking for from us. For further queries, don't hesitate to get in touch with us. ",
          category: 'Tab-1'
        }
      ],
      name: '',
      email: '',
      location: '',
      company: '',
      errorMsg: '',
      formSubmit: true,
      formSuccess: false
    }
  },
  methods:{
    playVideo(){
      if(!this.player) return;
      this.player.play();
      this.playActive = true
    },
    postNow(e) {
      e.preventDefault();
      this.errorMsg = "";
      var _this = this;
      if (this.name !== "" && this.email !== "" && this.location !== "" && this.company !== ""){
        axios.post("https://formsubmit.co/ajax/94f36650bc55b68802223368f9b6aba6", {
          headers: {
            'Content-type': 'application/json',
          },
          body: {name:this.name,email:this.email,location:this.location,company:this.company},
        })
            .then(function () {
              _this.formSubmit = false
              _this.formSuccess = true
              _this.name = "";
              _this.email = "";
              _this.location = "";
              _this.company = "Thank you For your message";
            })
            .catch(()=>{
            })
      }else {
        this.errorMsg = "Please fill all field";
      }

    }
  }
}
</script>


