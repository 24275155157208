<template>
  <header id="header">
    <div class="container">
      <div class="row">
        <div class="col-md-3 align-self-center">
          <router-link to="/" class="logo">
            <img src="../assets/image/logo.png" alt="logo">
            <img src="../assets/image/logo-text.png" alt="logo">
          </router-link>
        </div>
        <!--        <span id="menu-btn" @click="isActive = !isActive" :class="{ active: isActive }"></span>-->
        <div class="col-md-6 main-menu align-self-center">
          <span id="menu-btn" @click="activeMenu = !activeMenu" :class="{active : activeMenu}" class="svg-002-menu"></span>
          <div id="navbar" :class="{active : activeMenu}">
            <ul class="nav-list">
              <li class="nav-item">
                <a href="#about-us" class="nav-link">ABOUT</a>
              </li>
              <li class="nav-item">
                <a href="#our-works" class="nav-link">WORK</a>
              </li>
              <li class="nav-item">
                <a href="#faq" class="nav-link">FAQ</a>
              </li>
              <li class="nav-item">
                <a href="#footer" class="nav-link">CONTACT US</a>
              </li>
              <li class="d-block d-md-none">
                <div class="select-language">
                  <div class="aselect" :data-value="value" :data-list="list">
                    <div class="selector" @click="toggle()">
                      <div class="label">
                        <span>{{ value }}</span>
                      </div>
                      <div class="arrow" :class="{ expanded : visible }"></div>
                      <div :class="{ hidden : !visible, visible }">
                        <ul>
                          <li :class="{ current : item === value }" v-for="item in list" @click="select(item)" :key="item.id">{{ item }}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

        </div>
        <div class="col-md-3 align-self-center d-none d-md-block">
          <div class="select-language">
            <div class="aselect" :data-value="value" :data-list="list">
              <div class="selector" @click="toggle()">
                <div class="label">
                  <span>{{ value }}</span>
                </div>
                <div class="arrow" :class="{ expanded : visible }"></div>
                <div :class="{ hidden : !visible, visible }">
                  <ul>
                    <li :class="{ current : item === value }" v-for="item in list" @click="select(item)" :key="item.id">{{ item }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

</template>
<style lang="scss">
</style>
<script>

export default {
  props: {

  },
  components: {},
  data: function () {
    return {
      activeMenu: false,
      value: 'En',
      list: ["En","Fr"],
      visible: false
    }
  },
  methods: {
    toggle() {
      this.visible = !this.visible;
    },
    select(option) {
      this.value = option;
    }
  },
  mounted() {
  }
}
</script>
